import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../lib/Sentry';
import * as actions from '../store/actions/index';
import {Helmet} from "react-helmet";
import axiosInstance from "../components/axios";
import {isCordova, logger, nl2br, openWindow} from "../shared/utility";
import Footer from "../components/Footer";
import {InlineShareButtons} from 'sharethis-reactjs';
import {mpoAppStore} from "../lib/AppStore";
import {mpoShareThis} from "../lib/ShareThis";
import {mpoOneSignal} from "../lib/OneSignal";

class Home extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isLoading: true,
            merchant: null,
            layout: null,
            blocks: null,
            place_order_target: null,
            embed_data: null,
            embed_show_place_order: false,
            iframe_url: null,
            iframe_show_place_order: false,
        }

        this.topOfPage = React.createRef();
    }

    sendToServer = () => {

        const data = {
            RequestAction: 'AppHome'
        };
        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        axiosInstance.post(null, data)
            .then(response => {
                //console.log(response);
                if (response.data.ResponseCode === "SUCCESS") {
                    if (response.data.Response.hasOwnProperty('redirect_uri') && response.data.Response.redirect_uri !== undefined && response.data.Response.redirect_uri !== '') {
                        if (this.props.hasOwnProperty('history') && this.props.history !== undefined) {
                            this.props.history.push(response.data.Response.redirect_uri);
                        } else {
                            window.location.href = window.location.protocol + '//' + window.location.hostname + '/#' + response.data.Response.redirect_uri;
                        }
                    } else {
                        const placeOrderTarget = this.extractPlaceOrderTarget(response.data.Response.blocks);
                        this.setState({isLoading: false, merchant: response.data.Response.merchant, layout: response.data.Response.layout, blocks: response.data.Response.blocks, place_order_target: placeOrderTarget});
                    }
                } else {
                    mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                    mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                }

            })
            .catch(error => {
                //console.log(error);
                mobiscroll.toast({message: 'Error HO1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                mpoSentry.captureException(error);
            });
    }

    extractPlaceOrderTarget = (data) => {
        for (const [key, value] of Object.entries(data)) {
            if (value.block === 'place_order') return value.target;
        }
        return null;
    }

    fetchEmbedData = (url, show_place_order) => {
        (async () => {
            try {
                var response = await fetch(url);
                switch (response.status) {
                    // status "OK"
                    case 200:
                        var template = await response.text();
                        //console.log(template);
                        this.setState({embed_data: template, embed_show_place_order: show_place_order, iframe_url: null});
                        break;
                    default:
                        this.setState({embed_data: "<p>" + response.status + " " + response.statusText + "</p>", embed_show_place_order: show_place_order, iframe_url: null});
                }
            } catch (error) {
                //console.log(error);
                this.setState({embed_data: "<p>ERROR " + error.toString() + "</p>", embed_show_place_order: show_place_order, iframe_url: null});
            }
        })();
    }

    componentDidMount = () => {

        mpoSentry.addBreadcrumb('nav','Home',Sentry.Severity.Info);

        if (this.state.isLoading) {
            this.sendToServer();
        }

    }

    getAnnouncementJsx = (announcement) => {
        return <React.Fragment key={announcement.id}>
                <mobiscroll.CardSubtitle>{announcement.created} {announcement.title}</mobiscroll.CardSubtitle>
                <p dangerouslySetInnerHTML={{__html: nl2br(announcement.content)}} style={{marginTop: "0"}}></p>
            </React.Fragment>
    }

    getBlockJsx = (block) => {

        if (block.hasOwnProperty('visible') && block.visible === false) {
            return null;
        }

        let content = null;
        let formGroupStyle = block.hasOwnProperty('form_group_style') ? JSON.parse(block.form_group_style) : null;
        const imgCardStyle = block.hasOwnProperty('image_card_style') ? JSON.parse(block.image_card_style) : null;
        const btnIcon = block.hasOwnProperty('icon') && block.icon !== '' ? 'empty icon fas fa-'+block.icon : '';

        switch (block.block) {
            case 'welcome':
                //formGroupStyle = {"marginTop":"0"};
                content = <React.Fragment>
                    {block.image_url ?
                        block.hasOwnProperty('use_card') && block.use_card ?
                            <mobiscroll.Card>
                                <mobiscroll.CardContent style={imgCardStyle}>
                                    <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                                </mobiscroll.CardContent>
                            </mobiscroll.Card>
                            :
                            <div className="mbsc-align-center">
                                <img src={block.image_url} alt="Hero" style={imgCardStyle} onError={(e)=>{e.target.onerror = null; this.style.display='none'; }} />
                            </div>
                        :
                        null
                    }
                    {block.content ?
                        <mobiscroll.Card>
                            <mobiscroll.CardContent>
                                <mobiscroll.CardTitle>{block.title}</mobiscroll.CardTitle>
                                <p dangerouslySetInnerHTML={{__html: nl2br(block.content)}}></p>
                            </mobiscroll.CardContent>
                        </mobiscroll.Card>
                        : null
                    }
                </React.Fragment>
                break;
            case 'image':
                content = block.image_url ?
                    block.hasOwnProperty('use_card') && block.use_card ?
                        <mobiscroll.Card>
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Image" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                        </mobiscroll.Card>
                        :
                        <div className="mbsc-align-center">
                            <img src={block.image_url} alt="Image" style={imgCardStyle} onError={(e)=>{e.target.onerror = null; this.style.display='none'; }} />
                        </div>
                    :
                    null
                break;
            case 'announcements':
                let announcementsJsx = null
                const pushNotifsJsx = isCordova() && !mpoOneSignal.IsRegistered() && block.hasOwnProperty('show_push_register') && block.show_push_register ?
                        <mobiscroll.Button icon={btnIcon} block={true} color="info" onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>Get Notified</mobiscroll.Button>
                    : null;

                if (block.content.length === 0) {
                    announcementsJsx = <p dangerouslySetInnerHTML={{__html: nl2br(block.hasOwnProperty('nocontent') && block.nocontent !== '' ? block.nocontent : 'No new announcements')}} style={{marginTop: "0"}}></p>;
                } else {
                    announcementsJsx = block.content.map(content => {
                        return this.getAnnouncementJsx(content)
                    });
                }
                content = <mobiscroll.Card>
                    <mobiscroll.CardContent>
                        <mobiscroll.CardTitle style={{marginBottom: "0.5em"}}>{block.title}</mobiscroll.CardTitle>
                        {announcementsJsx}
                        {pushNotifsJsx}
                    </mobiscroll.CardContent>
                </mobiscroll.Card>
                break;
            case 'place_order':
                content = block.image_url ?
                        <mobiscroll.Card onClick={(e) => { this.props.history.push('/'+block.target) }}>
                            <div className="md-card-overlay">
                                <mobiscroll.CardContent style={imgCardStyle}>
                                    <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                                </mobiscroll.CardContent>
                                <mbsc-footer>
                                    <mobiscroll.Button icon={btnIcon} block={true} color="success" onClick={(e) => { this.props.history.push('/'+block.target) }}>{block.title}</mobiscroll.Button>
                                </mbsc-footer>
                            </div>
                        </mobiscroll.Card>
                        :
                        <div className="mbsc-padding" style={{paddingBottom: 0}}>
                            <mobiscroll.Button icon={btnIcon} block={true} color="success" onClick={(e) => { this.props.history.push('/'+block.target) }}>{block.title}</mobiscroll.Button>
                        </div>
                break;
            case 'embed_link':
                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { e.preventDefault(); this.fetchEmbedData(block.link_url, parseInt(block.place_order_btn,10) === 1) }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); this.fetchEmbedData(block.link_url, parseInt(block.place_order_btn,10) === 1) }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0}}>
                        <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); this.fetchEmbedData(block.link_url, parseInt(block.place_order_btn,10) === 1) }}>{block.title}</mobiscroll.Button>
                    </div>
                break;
            case 'share':
                if (block.share_url === null || block.share_url === '') {
                    return null;
                }
                const shareJsx = mpoShareThis.getShareThisJsx();
                content = <div className="mbsc-padding" style={{paddingBottom: 0, textAlign: block.alignment}}>
                    {block.heading ? <div className="mbsc-form-group-title" style={{borderBottom: "none"}}>{block.heading}</div> : null }
                    {shareJsx}
                    {/*
                    <InlineShareButtons
                        config={{
                            alignment: block.alignment,  // alignment of buttons (left, center, right)
                            color: 'social',      // set the color of buttons (social, white)
                            enabled: true,        // show/hide buttons (true, false)
                            font_size: 16,        // font size for the buttons
                            labels: 'cta',        // button labels (cta, counts, null)
                            language: 'en',       // which language to use (see LANGUAGES)
                            networks: [           // which networks to include (see SHARING NETWORKS)
                                'whatsapp',
                                'messenger',
                                'facebook',
                                'twitter',
                                'email'
                            ],
                            padding: 12,          // padding within buttons (INTEGER)
                            radius: 4,            // the corner radius on each button (INTEGER)
                            show_total: false,
                            size: 40,             // the size of each button (INTEGER)


                            // OPTIONAL PARAMETERS

                            min_count: 10,                    // (threshold for total share count to be displayed)
                            url: block.share_url, // (defaults to current url)
                            image: block.image_url,  // (defaults to og:image or twitter:image)
                            description: block.description,       // (defaults to og:description or twitter:description)
                            title: block.title,            // (defaults to og:title or twitter:title)
                            message: block.message,     // (only for email sharing)
                            subject: block.subject,  // (only for email sharing)
                            username: block.username // (only for twitter sharing)
                        }}
                    />
                    */}
                </div>
                break;
            case 'account':
                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { e.preventDefault(); this.props.history.push('/account/'+block.target) }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/'+block.target) }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0}}>
                        <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/account/'+block.target) }}>{block.title}</mobiscroll.Button>
                    </div>
                break;
            case 'google_review':
                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { e.preventDefault(); openWindow(block.link_url, '_system', 'location=no,menubar=no,status=no,toolbar=no', false) }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); openWindow(block.link_url, '_system', 'location=no,menubar=no,status=no,toolbar=no', false) }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0, textAlign: "center"}}>
                            <img alt="Google Review"
                                 src="./img/appstores/review-us-on-google.png"
                                 onClick={(e) => { e.preventDefault(); openWindow(block.link_url, '_system', 'location=no,menubar=no,status=no,toolbar=no', false) }}/>
                    </div>
                break;
            case 'internal_link':
                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { e.preventDefault(); this.props.history.push('/'+block.target) }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/'+block.target) }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0}}>
                        <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); this.props.history.push('/'+block.target) }}>{block.title}</mobiscroll.Button>
                    </div>
                break;
            case 'external_link':
                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { e.preventDefault(); openWindow(block.link_url, '_system', 'location=no,menubar=no,status=no,toolbar=no', false) }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); openWindow(block.link_url, '_system', 'location=no,menubar=no,status=no,toolbar=no', false) }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0}}>
                        <mobiscroll.Button icon={btnIcon} block={true} onClick={(e) => { e.preventDefault(); openWindow(block.link_url, '_system', 'location=no,menubar=no,status=no,toolbar=no', false) }}>{block.title}</mobiscroll.Button>
                    </div>
                break;
            case 'iframe':
                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { this.setState({embed_data: null, iframe_url: block.link_url, iframe_show_place_order: parseInt(block.place_order_btn,10) === 1}); }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button block={true} onClick={(e) => { this.setState({embed_data: null, iframe_url: block.link_url, iframe_show_place_order: parseInt(block.place_order_btn,10) === 1}); }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0}}>
                        <mobiscroll.Button block={true} onClick={(e) => { this.setState({embed_data: null, iframe_url: block.link_url, iframe_show_place_order: parseInt(block.place_order_btn,10) === 1}); }}>{block.title}</mobiscroll.Button>
                    </div>
                break;
            case 'push_notifs':
                if (!isCordova() || mpoOneSignal.IsRegistered()) {
                    return null;
                }

                content = block.image_url ?
                    <mobiscroll.Card onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>
                        <div className="md-card-overlay">
                            <mobiscroll.CardContent style={imgCardStyle}>
                                <img src={block.image_url} alt="Hero" className="md-card-hero" onError={(e)=>{e.target.onerror = null; }} />
                            </mobiscroll.CardContent>
                            <mbsc-footer>
                                <mobiscroll.Button icon={btnIcon} color="ïnfo" block={true} onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>{block.title}</mobiscroll.Button>
                            </mbsc-footer>
                        </div>
                    </mobiscroll.Card>
                    :
                    <div className="mbsc-padding" style={{paddingBottom: 0}}>
                        <mobiscroll.Button icon={btnIcon} block={true} color="ïnfo" onClick={(e) => { e.preventDefault(); mpoOneSignal.DisplayRegisterPopup(); }}>{block.title}</mobiscroll.Button>
                    </div>
                break;

            case 'app_download':
                const appDownloadInfo = mpoAppStore.getAppDownloadInfo();
                if (appDownloadInfo !== null && appDownloadInfo.downloadAppUrl !== "") {
                    content = <div style={{paddingTop: 0, paddingBottom: 0, textAlign: "center"}}>
                        <div className="mbsc-form-group-title" style={{borderBottom: "none"}}>{block.title}</div>
                        <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                            <mobiscroll.Button block={true} data-icon={appDownloadInfo.downloadAppIcon} onClick={(e) => { e.preventDefault(); mpoAppStore.downloadApp(appDownloadInfo.downloadAppUrl); }}>{appDownloadInfo.downloadAppText}</mobiscroll.Button>
                        </div>
                    </div>
                } else if (!isCordova() && (process.env.REACT_APP_ITUNES_APP_URL !== "" || process.env.REACT_APP_GOOGLE_APP_URL !== "")) {
                    content = <div style={{paddingTop: 0, paddingBottom: 0, textAlign: "center"}}>
                        <div className="mbsc-form-group-title" style={{borderBottom: "none"}}>{block.title}</div>
                        {process.env.REACT_APP_ITUNES_APP_URL !== "" ?
                            <a href={process.env.REACT_APP_ITUNES_APP_URL} target="_blank">
                                <img alt="Apple App Store"
                                     src="./img/appstores/Download_on_the_App_Store_Badge_US-UK_135x40.png" /></a>
                            : null}
                        {process.env.REACT_APP_GOOGLE_APP_URL !== "" ?
                            <a href={process.env.REACT_APP_GOOGLE_APP_URL} target="_blank">
                                <img alt="Google Play Store"
                                     src="./img/appstores/en_app_rgb_wo_45.png" /></a>
                            : null}
                    </div>
                }
                break;
            default:
                return null
        }
        if (content !== null) {
            return <div className="mbsc-form-group" style={formGroupStyle} key={block.id}>{content}</div>
        }
        return null;
    }

    render = () => {

        const titleJsx = <Helmet>
            <title>{`${process.env.REACT_APP_APP_TITLE} Home`}</title>
        </Helmet>

        const stickyFooterJsx = <div className="mbsc-padding sticky-footer">
            <div className="mbsc-form-group" style={{margin: "auto", maxWidth: "1200px"}}>
            {this.state.place_order_target &&
                ((this.state.embed_data !== null && this.state.embed_show_place_order) || (this.state.iframe_url !== null && this.state.iframe_show_place_order)) ?
                <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                    <mobiscroll.Button block={true} color="success" onClick={(e) => { this.props.history.push('/'+this.state.place_order_target) }}>Place an Order</mobiscroll.Button>
                </div> : null}
            <div className="mbsc-padding" style={{paddingTop: 0, paddingBottom: 0}}>
                <mobiscroll.Button icon="arrow-left5" block={true} onClick={(e) => { e.preventDefault(); window.location.reload(); }}>Back to Home</mobiscroll.Button>
            </div>
            </div>
        </div>

        if (this.state.isLoading) {
            return (
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked">
                    <div className="app-tab" ref={this.topOfPage}>
                        <div className="mbsc-form-group">
                            <div className="mbsc-form-group-title">Loading...</div>
                        </div>
                    </div>
                </mobiscroll.Form>
            );
        } else if (this.state.embed_data !== null) {
            return (
                <div className="app-tab">
                    <mobiscroll.Form className="mpo-form-width-responsive" labelStyle="stacked">
                        {titleJsx}
                        <div dangerouslySetInnerHTML={{__html: this.state.embed_data}}></div>
                        {stickyFooterJsx}
                    </mobiscroll.Form>
                </div>
            );
        } else if (this.state.iframe_url !== null) {
            const iframeHeight = window.innerHeight - (this.state.iframe_show_place_order ? 180 : 90)
            return (
                <div className="app-tab">
                    <mobiscroll.Form className="mpo-form-width-responsive" labelStyle="stacked">
                        {titleJsx}
                        <div dangerouslySetInnerHTML={{__html: "<iframe src=\""+this.state.iframe_url+"\" onload=\"window.parent.parent.scrollTo(0,0)\" width=\"100%\" height=\""+iframeHeight+"\" allowpaymentrequest=\"false\" style=\"max-width: 100%;border:none;overflow:auto;\"></iframe>"}}></div>
                        {stickyFooterJsx}
                    </mobiscroll.Form>
                </div>
            );
        }

        const blocksJsx = this.state.blocks.map(block => {
            return this.getBlockJsx(block)
        });

        const formStyle = this.state.hasOwnProperty('form_style') && this.state.form_style !== null ? JSON.parse(this.state.form_style) : null;

        return (
            <div className="app-tab" style={{paddingBottom: 0}}>
                <mobiscroll.Form className="mpo-form-width-responsive" labelStyle="stacked" style={formStyle}>
                    {titleJsx}
                    {blocksJsx}
                </mobiscroll.Form>
                <Footer merchant={this.state.merchant} />
            </div>
        );

    }
}

const mapStateToProps = state => {
    return {
        user: state.user
    }
}

const mapDispatchToProps = dispatch => {
    return {
        updateStateWithCustomer: (customer, ownProps) => {
            const redirect = '/account/billing';
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        }
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Home));
