import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
    logger,
    attachDeviceInfoToData,
    isCordova,
    isAppleSigninLoaded,
    isGoogleSigninLoaded,
    openWindow, isAppModePersonal, isAppModeKiosk, isAppModePos, getAppMode
} from '../../shared/utility';
import mobiscroll from "@mobiscroll/react";
import * as Sentry from '@sentry/browser';
import { mpoSentry } from '../../lib/Sentry';
import * as actions from '../../store/actions/index';
import axiosInstance from '../../components/axios';
import { validateEmail, validateMobile, validateAccountPin } from '../../shared/validate'
import { mpoOneSignal } from '../../lib/OneSignal';
import AppleLogin from 'react-apple-login'
import { GoogleLogin } from '@react-oauth/google';
import { isExpired, decodeToken } from "react-jwt";
import {Helmet} from "react-helmet";
import queryString from "query-string";

const debugMode = process.env.REACT_APP_APP_DEBUG !== undefined && process.env.REACT_APP_APP_DEBUG === 'true';
const defaultCountry = process.env.REACT_APP_COUNTRY ? process.env.REACT_APP_COUNTRY : "";
const defaultState = process.env.REACT_APP_STATE ? process.env.REACT_APP_STATE : "";
// signin with apple
const isAppleSigninEnabled = process.env.REACT_APP_APPLE_SIGNIN_ENABLED === 'true';
const appleSigninClientId = isAppleSigninEnabled && process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID ? process.env.REACT_APP_APPLE_SIGNIN_CLIENT_ID : "";
const appleSigninRedirectUrl = process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URL ? process.env.REACT_APP_APPLE_SIGNIN_REDIRECT_URL : "https://app.mypreorder.com.au/services/s/apple.php";
// signin with google
const isGoogleSigninEnabled = process.env.REACT_APP_GOOGLE_SIGNIN_ENABLED === 'true';
const googleSigninClientId = isGoogleSigninEnabled && process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID ? process.env.REACT_APP_GOOGLE_SIGNIN_CLIENT_ID : "";
//const googleSigninRedirectUrl = process.env.REACT_APP_GOOGLE_SIGNIN_REDIRECT_URL ? process.env.REACT_APP_GOOGLE_SIGNIN_REDIRECT_URL : "https://app.mypreorder.com.au/services/s/google.php";
// compliance
const termsUrl = process.env.REACT_APP_APP_TERMS_URL !== undefined && process.env.REACT_APP_APP_TERMS_URL !== null && process.env.REACT_APP_APP_TERMS_URL !== "" ? process.env.REACT_APP_APP_TERMS_URL : null;
const privacyUrl = process.env.REACT_APP_APP_PRIVACY_URL !== undefined && process.env.REACT_APP_APP_PRIVACY_URL !== null && process.env.REACT_APP_APP_PRIVACY_URL !== "" ? process.env.REACT_APP_APP_PRIVACY_URL : null;

let isAppleSigninAvailable = isAppleSigninEnabled && ((isCordova() && mobiscroll.platform.name === 'ios' /*&& isAppleSigninLoaded()*/) || (!isCordova() && mobiscroll.platform.name !== 'android'  && appleSigninClientId !== "" && appleSigninRedirectUrl !== ""));
let isGoogleSigninAvailable = isGoogleSigninEnabled && googleSigninClientId !== ""; // && (!isCordova() || isGoogleSigninLoaded());
let anyThirdPartySigninAvailable = isAppleSigninAvailable || isGoogleSigninAvailable;

let emailPasswordToggleEnabled = true;
let pinPasswordToggleEnabled = true;

// to preserve any state needed when using an oauth redirect, e.g. apple signin on web
let jwtState = null;

class AccountLogin extends Component {

    constructor(props) {
        super(props);
        if (debugMode) {
            logger('ACCOUNT LOGIN');
            logger(getAppMode());
            // logger(props);
            // logger(this.props);
        }

        let email = "";
        let pass = "";
        let mobile = "";
        let pin = "";
        let isMobileLogin = false;
        if (this.props.user.auth.hasOwnProperty('login') && this.props.user.auth.login !== undefined && 
            this.props.user.auth.login.hasOwnProperty('method') && this.props.user.auth.login.method !== undefined) {
            if (this.props.user.auth.login.method === 'email') {
                if (this.props.user.auth.login.hasOwnProperty('value') && this.props.user.auth.login.value !== undefined && this.props.user.auth.login.value !== "") {
                    email = this.props.user.auth.login.value;
                    pass = 'token|'+this.props.user.auth.login.token;
                    emailPasswordToggleEnabled = false;
                }
            } else if (this.props.user.auth.login.method === 'mobile') {
                if (this.props.user.auth.login.hasOwnProperty('value') && this.props.user.auth.login.value !== undefined && this.props.user.auth.login.value !== "") {
                    mobile = this.props.user.auth.login.value;
                    pin = 'token|'+this.props.user.auth.login.token;
                    pinPasswordToggleEnabled = false;
                }
                isMobileLogin = true;
            }
        }

        if (!isCordova()) {
            let returnUrl = window.location.protocol + '//' + window.location.hostname;
            if (this.props.hasOwnProperty('history') && this.props.history !== undefined &&
                this.props.history.hasOwnProperty('location') && this.props.history.location !== undefined &&
                this.props.history.location.hasOwnProperty('pathname') && this.props.history.location.pathname !== undefined) {
                returnUrl += '/#' + this.props.history.location.pathname;
            }
            jwtState = {
                return_url: returnUrl,
                next: null,
            };
            if (this.props.hasOwnProperty('location') && this.props.location !== undefined &&
                this.props.location.hasOwnProperty('state') && this.props.location.state !== undefined) {
                if (this.props.location.state.hasOwnProperty('next')) {
                    jwtState.next = this.props.location.state.next;
                } else if (this.props.location.state.hasOwnProperty('from') && this.props.location.state.from !== '/login') {
                    jwtState.next = this.props.location.state.from;
                }
            }
            if (debugMode) {
                // logger(appleSigninClientId);
                // logger(appleSigninRedirectUrl);
                logger(jwtState);
            }
        }

        this.state = {
            isLogin: true,
            isMobileLogin: isMobileLogin,
            isFacebookLogin: false,
            btnText: 'Sign in',
            forgotBtnText: isMobileLogin ? 'Reset forgotten PIN' : 'Reset forgotten password',
            mobileBtnText: isMobileLogin ? "Sign in with your email & password" : "Sign in with your mobile number & PIN",
            signup: "Don't have an account yet? Sign up.",
            name: '',
            nameValid: true,
            nameError: '',
            email: email,
            emailValid: true,
            emailError: '',
            pass: pass,
            passValid: true,
            passError: '',
            mobile: mobile,
            mobileValid: true,
            mobileError: '',
            pin: pin,
            pinValid: true,
            pinError: '',
            country: defaultCountry !== 'undefined' && defaultCountry !== '' ? defaultCountry : '',
            countryValid: true,
            countryError: '',
            state: defaultState !== 'undefined' && defaultState !== '' ? defaultState : '',
            stateValid: true,
            stateError: '',
            rememberMe: true,
            submitted: false
        };

        //this.props.updateStateWithCustomer({id: 0}, this.props);
    }

    componentDidMount = () => {
        if (debugMode) {
            logger('Login componentDidMount');
            if (this.props.hasOwnProperty('location') && this.props.location !== undefined &&
                this.props.location.hasOwnProperty('search') && this.props.location.search !== undefined) {
                logger(this.props.location.search);
            }
            logger('googleSigninClientId='+googleSigninClientId);
            logger('isGoogleSigninEnabled='+isGoogleSigninEnabled);
            logger('isGoogleSigninAvailable='+isGoogleSigninAvailable);
            logger('anyThirdPartySigninAvailable='+anyThirdPartySigninAvailable);
        }

        mpoSentry.addBreadcrumb('nav','Login',Sentry.Severity.Info);

        const params = (this.props.hasOwnProperty('location') && this.props.location !== undefined &&
            this.props.location.hasOwnProperty('search') && this.props.location.search !== undefined) ? queryString.parse(this.props.location.search) : {};
        const method = params.method ? params.method : null;
        //const method = params.hasOwnProperty('method') && params.method !== undefined ? params.method : null;

        switch (method) {
            case "apple":
                //const code = params.code ? params.code : null;
                const id_token = params.id_token ? params.id_token : null;
                const user = params.user ? JSON.parse(params.user) : null;
                const state = params.state ? JSON.parse(params.state) : null;

                if (id_token) {
                    const decodedIdToken = decodeToken(id_token);
                    if (debugMode) logger(decodedIdToken);
                    const tokenIsExpired = isExpired(id_token);
                    if (tokenIsExpired) {
                        logger('Apple token has expired');
                        mobiscroll.toast({message: 'Apple Signin Token has expired', color: 'danger'});
                    } else {
                        const data = {
                            user: decodedIdToken.sub, // apple user id
                            email:decodedIdToken.email,
                            fullName: {
                                familyName: user && user.lastName ? user.lastName : '',
                                givenName: user && user.firstName ? user.firstName : '',
                            }
                        };
                        const redirect = state && state.next ? state.next : null;
                        this.responseApple(data, redirect);
                    }
                }
                break;
            case "google":
                if (debugMode) {
                    logger('GOOGLE login');
                    logger(params);
                }
                break;
            default:
                if (debugMode) {
                    logger("Unknown or no login method param");
                }
        }

    }

    componentDidUpdate = () => {
        if (debugMode) logger('Login componentDidUpdate');
    }

    onNameChange = (event) => {
        const invalid = event.target.value === '' ? 'This field is required' : false;
        this.setState({
            name: event.target.value,
            nameValid: !this.state.submitted || !invalid,
            nameError: invalid || ''
        });
    }

    onEmailChange = (event) => {
        const invalid = validateEmail(event.target.value) ? false : 'Enter a valid email address';
        //console.log(invalid);
        this.setState({
            email: event.target.value,
            emailValid: !this.state.submitted || !invalid,
            emailError: invalid || ''
        });
    }
    
    onPasswordChange = (event) => {
        const invalid = event.target.value === '' ? 'This field is required' : false;
        this.setState({
            pass: event.target.value,
            passValid: !this.state.submitted || !invalid,
            passError: invalid || ''
        });
    }
    
    onMobileChange = (event, inst) => {
        const invalid = event.valueText === '' ? 'This field is required' : false; 
        this.setState({
            mobile: event.valueText,
            mobileValid: !this.state.submitted || !invalid,
            mobileError: invalid || ''
        });
    }

    onPinChange = (event, inst) => {
        const invalid = event.valueText === '' ? 'This field is required' : false; 
        this.setState({
            pin: event.valueText,
            pinValid: !this.state.submitted || !invalid,
            pinError: invalid || ''
        });
    }

    setCountry = (event, inst) => {
        const invalid = inst.getVal() === '' ? 'This field is required' : false;
        this.setState({
            country: inst.getVal(),
            countryValid: !this.state.submitted || !invalid,
            countryError: invalid || ''
        });
    }

    setStateTerritory = (event, inst) => {
        const invalid = inst.getVal() === '' && this.state.country === 'AU' ? 'This field is required' : false;
        this.setState({
            state: inst.getVal(),
            stateValid: !this.state.submitted || !invalid,
            stateError: invalid || ''
        });
    }

    signUp = (event) => {
        const login = !this.state.isLogin;
        const isMobileLogin = login && this.state.isMobileLogin;
        event.preventDefault();

        let country = defaultCountry !== 'undefined' && defaultCountry !== '' ? defaultCountry : '';
        let state = defaultState !== 'undefined' && defaultState !== '' ? defaultState : '';
        if (this.props.user.hasOwnProperty('customer') && this.props.user.customer) {
            if (this.props.user.customer.hasOwnProperty('country_code') && this.props.user.customer.country_code !== '') {
                country = this.props.user.customer.country_code;
            }
            if (this.props.user.customer.hasOwnProperty('timezone_state') && this.props.user.customer.timezone_state !== '') {
                state = this.props.user.customer.timezone_state;
            }
        }
        if (country === '' && this.props.merchants && this.props.merchants.hasOwnProperty('merchants_nearby') && this.props.merchants.merchants_nearby.length > 0) {
            country = this.props.merchants.merchants_nearby[0].country_code;
            state = this.props.merchants.merchants_nearby[0].merchant_state;
        }

        this.setState({
            submitted: false,
            signup: login ? "Don't have an account yet? Sign up." : "Already have an account? Sign in.",
            btnText: login ? "Sign in" : "Sign up",
            forgotBtnText: isMobileLogin ? 'Reset forgotten PIN' : 'Reset forgotten password',
            mobileBtnText: isMobileLogin ? "Sign in with your email & password" : "Sign in with your mobile number & PIN",
            isLogin: login,
            isMobileLogin: isMobileLogin,
            name: this.props.user.customer.name,
            nameValid: true,
            nameError: '',
            email: this.props.user.customer.email,
            emailValid: true,
            emailError: '',
            pinValid: true,
            pinError: '',
            mobile: this.props.user.customer.mobile,
            mobileValid: true,
            mobileError: '',
            country: country,
            countryValid: true,
            countryError: '',
            state: state,
            stateValid: true,
            stateError: '',
        });
    }

    mobileLogin = (event) => {
        const isMobileLogin = !this.state.isMobileLogin;
        event.preventDefault();
        this.setState({
            forgotBtnText: isMobileLogin ? 'Reset forgotten PIN' : 'Reset forgotten password',
            mobileBtnText: isMobileLogin ? "Sign in with your email & password" : "Sign in with your mobile number & PIN",
            isMobileLogin: isMobileLogin
        });
    }

    password = (event) => {
        event.preventDefault();
    }
    
    submit = (event) => {
        event.preventDefault();

        /*
        const emailInvalid = validateEmail(this.state.email) ? false : 'Enter a valid email address';
        const passInvalid = this.state.pass === '' ? 'This field is required' : false;
        const mobileInvalid = this.state.mobile === '' ? 'This field is required' : false;
        const pinInvalid = this.state.pass === '' ? 'This field is required' : false;

        const updatedState = {
            submitted: true,
            emailValid: !emailInvalid,
            emailError: emailInvalid || '',
            passValid: !passInvalid,
            passError: passInvalid || '',
            mobileValid: !mobileInvalid,
            mobileError: mobileInvalid || '',
            pinValid: !pinInvalid,
            pinError: pinInvalid || '',
        }
        */

        this.sendToServer();
        /*
        if ((updatedState.isMobileLogin && updatedState.mobileValid && updatedState.pinValid) ||
            (!updatedState.isMobileLogin && updatedState.emailValid && updatedState.passValid)) {
            // send to server, and redirect 
            this.sendToServer();
        } else {
            this.setState(updateObject(this.state, updatedState));
        }
        */
    }

    sendToServer = () => {

        const data = {
            RequestAction: this.state.isLogin ? 'Login' : 'Register',
            method: this.state.isMobileLogin ? 'mobile' : 'email'
        };
        mpoSentry.addBreadcrumb('action',data.RequestAction,Sentry.Severity.Info);

        attachDeviceInfoToData(data);
        if (isCordova() && mpoOneSignal.IsRegistered()) {
            data.pn_data = mpoOneSignal.GetPnData();
        }

        if (this.state.isLogin) {
            if (this.state.isMobileLogin) {
                data.mobile = this.state.mobile;
                data.pin = this.state.pin;
            } else {
                data.email = this.state.email;
                data.password = this.state.pass;
            }
            data.remember_me = this.state.rememberMe ? 1 : 0;
        } else {
            data.name = this.state.name;
            data.email = this.state.email;
            data.mobile = this.state.mobile;
            data.password1 = this.state.pass;
            data.password2 = this.state.pass;
            data.country_code = this.state.country;
            data.state = this.state.state;
        }

        axiosInstance.post(null, data)
        .then(response => {
            //console.log(response);
            if (response.data.ResponseCode === "SUCCESS") {
                // set customer and redirect
                mobiscroll.toast({message: "Sign in successful", color: 'success'});
                let redirectUrl = response.data.Response.hasOwnProperty('menu_name') && response.data.Response.menu_name !== undefined && response.data.Response.menu_name !== '' ? ('/menu/'+response.data.Response.menu_name) : null;
                logger('Sign in success redir1: '+redirectUrl);
                //logger(response.data.Response);
                if ((parseInt(response.data.Response.status, 10) === 3 || parseInt(response.data.Response.status, 10) === 8) &&
                    response.data.Response.hasOwnProperty('eftpos') && response.data.Response.eftpos !== undefined &&
                    response.data.Response.eftpos.hasOwnProperty('is_eftpos_enabled') && response.data.Response.eftpos.is_eftpos_enabled !== undefined &&
                    parseInt(response.data.Response.eftpos.is_eftpos_enabled, 10) === 1) {
                    // redirect to ensure eftpos is configured and connected
                    if (response.data.Response.eftpos.hasOwnProperty('eftpos_provider') && response.data.Response.eftpos.eftpos_provider !== undefined &&response.data.Response.eftpos.eftpos_provider === 'LINKLY') {
                        redirectUrl = '/eftpos/linkly';
                        logger('Sign in success redir2: ' + redirectUrl);
                    } else {
                        logger('Unknown eftpos provider');
                        logger(response.data.Response.eftpos);
                    }
                }
                this.props.updateStateWithCustomer(response.data.Response, this.props, redirectUrl);
                Sentry.configureScope((scope) => {
                    scope.setUser({
                        "id": response.data.Response.id,
                        "email": response.data.Response.email
                    });
                });
                mpoSentry.addBreadcrumb('auth','Authenticated user ' + response.data.Response.email,Sentry.Severity.Info);
            } else {
                mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
            }

        })
        .catch(error => {
            //console.log(error);
            mobiscroll.toast({message: 'Error LI1, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
            mpoSentry.captureException(error);
        });

    }

    responseApple = (response, redirect) => {
        if (debugMode) {
            logger('responseApple called');
            logger('==response==');
            logger(JSON.stringify(response));
        }
        if (response.hasOwnProperty('user') && response.user !== undefined && response.user !== "") {

            const data = {
                RequestAction: 'Login',
                method: 'apple',
                payload: response
            };

            mpoSentry.addBreadcrumb('action','fbApple',Sentry.Severity.Info);

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    //logger(response);
                    if (response.data.ResponseCode === "SUCCESS") {
                        // set customer and redirect
                        mobiscroll.toast({message: "Signin successful", color: 'success'});
                        this.props.updateStateWithCustomer(response.data.Response, this.props, redirect);
                        Sentry.configureScope((scope) => {
                            scope.setUser({
                                "id": response.data.Response.id,
                                "email": response.data.Response.email
                            });
                        });
                        mpoSentry.addBreadcrumb('auth','Authenticated Apple user ' + response.data.Response.email,Sentry.Severity.Info);
                    } else {
                        //logger(response);
                        mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                    }

                })
                .catch(error => {
                    //logger(error);
                    mobiscroll.toast({message: 'Error LI3, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                });

        } else {
            logger(JSON.stringify(response));
            var msg = "Insufficient data from Apple to log you in. Access to your Name and Email address are required.";
            mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, Sentry.Severity.Error);
        }
    }

    cordovaAppleLogin = () => {
        if (isAppleSigninLoaded()) {
            /*eslint-disable no-undef*/
            window.cordova.plugins.SignInWithApple.signin(
                {requestedScopes: [0, 1]},
                response => {
                    //logger(response);
                    this.responseApple(response);
                },
                error => {
                    logger(error);
                    mobiscroll.toast({message: error.localizedDescription, color: 'danger'});
                    mpoSentry.captureMessage(error, Sentry.Severity.Warning);
                }
            )
            /*eslint-enable no-undef*/
        } else {
            var msg = "Sign in with Apple dependencies failed to load, please restart the app to try again.";
            mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, Sentry.Severity.Warning);
        }
    }

    cordovaGoogleLogin = () => {
        if (isGoogleSigninLoaded()) {
            /*eslint-disable no-undef*/
            if (debugMode) logger('Login cordovaGoogleLogin called');
            //if (debugMode) mobiscroll.toast({message: 'cordovaGoogleLogin called', color: 'success'});
            window.plugins.googleplus.login(
                {
                    webClientId: googleSigninClientId
                },
                response => {
                    this.responseGoogleSuccess(response);
                },
                error => {
                    logger(error);
                    mobiscroll.toast({message: error, color: 'danger'});
                    mpoSentry.captureMessage(error, Sentry.Severity.Warning);
                }
            );
            /*eslint-enable no-undef*/
        } else {
            var msg = "Sign in with Google unavailable (dependencies not loaded [L1])";
            //mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, Sentry.Severity.Warning);

            mobiscroll.snackbar({
                message: msg,
                color: 'danger',
                duration: 10000,
                button: {
                    text: 'Reload',
                    icon: 'empty icon fas fa-sync-alt',
                    action: (p) => {
                        window.location.reload();
                    }
                }
            });
        }
    }

    cordovaGoogleSilentLogin = () => {
        if (isGoogleSigninLoaded()) {
            /*eslint-disable no-undef*/
            if (debugMode) logger('cordovaGoogleSilentLogin called');
            window.plugins.googleplus.trySilentLogin(
                {
                    webClientId: googleSigninClientId
                },
                response => {
                    if (debugMode) logger(JSON.stringify(response));
                    this.responseGoogleSuccess(response);
                },
                error => {
                    logger(error);
                    mpoSentry.captureMessage("cordovaGoogleSilentLogin: "+error, Sentry.Severity.Warning);
                }
            );
            /*eslint-enable no-undef*/
        }
    }

    responseGoogleError = (response) => {
        if (debugMode) {
            logger('responseGoogleError called');
            logger('==response==');
            logger(JSON.stringify(response));
        }
        if (response.hasOwnProperty('error') && response.error !== undefined && response.error !== "") {
            if (response.error === "idpiframe_initialization_failed") {
                var msg = "Google Login unavailable (initialisation failed).";
                mpoSentry.captureMessage(msg, Sentry.Severity.Warning);
                mobiscroll.toast({message: msg, color: 'danger'});
                isGoogleSigninAvailable = false;
            } else if (response.hasOwnProperty('details') && response.details !== undefined && response.details !== "") {
                mobiscroll.toast({message: "Google Login error: "+response.details, color: 'danger'});
            } else {
                mobiscroll.toast({message: "Google Login error: "+response.error, color: 'danger'});
            }
            // if error === "10" then add keystore fingerprints to firebase app
        } else {
            var msg = "Error logging in with Google.";
            mpoSentry.captureMessage(msg, Sentry.Severity.Warning);
            mobiscroll.toast({message: msg, color: 'danger'});
        }
    }

    responseGoogleSuccess = (response) => {
        if (debugMode) {
            logger('responseGoogleSuccess called');
            logger('==response==');
            logger(JSON.stringify(response));
        }
        let payload = {};
        if (isCordova() && response.hasOwnProperty('email') && response.email !== undefined && response.email !== "") {
            // response from cordova plugin needs to be molded to meet our server expectations, which are based on new google oauth2 jwt...
            payload = response;
            payload.sub = response.userId;
            payload.name = response.displayName;
            payload.given_name = response.givenName;
            payload.family_name = response.familyName;
            payload.picture = response.imageUrl;
            payload.identityToken = response.idToken;
            payload.authorizationCode = response.serverAuthCode;
        }
        else if (response.hasOwnProperty('credential') && response.credential !== undefined && response.credential !== "") {
            // google oauth2 jwt
            const decodedIdToken = decodeToken(response.credential);
            payload = decodedIdToken;
            payload.identityToken = response.credential;
        }
        if (debugMode) {
            logger('==payload==');
            logger(payload);
        }

        if (payload.hasOwnProperty('email') && payload.email !== undefined && payload.email !== "") {
            const data = {
                RequestAction: 'Login',
                method: 'google',
                payload: payload
            };

            mpoSentry.addBreadcrumb('action','fbGoogle',Sentry.Severity.Info);

            attachDeviceInfoToData(data);
            if (isCordova() && mpoOneSignal.IsRegistered()) {
                data.pn_data = mpoOneSignal.GetPnData();
            }

            axiosInstance.post(null, data)
                .then(response => {
                    //logger(response);
                    if (response.data.ResponseCode === "SUCCESS") {
                        // set customer and redirect
                        mobiscroll.toast({message: "Signin successful", color: 'success'});
                        this.props.updateStateWithCustomer(response.data.Response, this.props);
                        Sentry.configureScope((scope) => {
                            scope.setUser({
                                "id": response.data.Response.id,
                                "email": response.data.Response.email
                            });
                        });
                        mpoSentry.addBreadcrumb('auth','Authenticated Google user ' + response.data.Response.email,Sentry.Severity.Info);
                    } else {
                        //logger(response);
                        mobiscroll.toast({message: response.data.Response[0], color: 'danger'});
                        mpoSentry.captureMessage(response.data.Response[0], Sentry.Severity.Warning);
                    }

                })
                .catch(error => {
                    //logger(error);
                    mobiscroll.toast({message: 'Error LI4, please try again. If the problem continues, please log out and log back in again.', color: 'danger'});
                    mpoSentry.captureException(error);
                });

        } else {
            logger(response);
            var msg = "Unknown response from Google.";
            mobiscroll.toast({message: msg, color: 'danger'});
            mpoSentry.captureMessage(msg, Sentry.Severity.Error);
        }
    }

    onRememberMeChange = (e) => {
        this.setState({
            rememberMe: e.target.checked
        });   
    }

    render = () => {
        return (
            <div className="app-tab">
                <Helmet>
                    <title>{`${process.env.REACT_APP_APP_TITLE} Sign In`}</title>
                </Helmet>

                {this.state.isLogin && (isAppModeKiosk() || isAppModePos()) ?
                    <mobiscroll.Form
                        className="mpo-form-width-md"
                        labelStyle="stacked"
                    >
                        <mobiscroll.FormGroup inset className="mbsc-align-center">
                            <div className="mbsc-form-group-title mbsc-align-center">{isAppModeKiosk() ? "Kiosk" : "Merchant"} Sign In</div>
                        </mobiscroll.FormGroup>
                    </mobiscroll.Form>
                    : null
                }

                {anyThirdPartySigninAvailable ?
                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked"
                >
                    <mobiscroll.FormGroup inset className="mbsc-align-center">
                        <div className="mbsc-form-group-title mbsc-align-center">{this.state.isLogin ? "Sign in with an existing account" : "Sign up"}</div>
                    </mobiscroll.FormGroup>

                    {isAppleSigninEnabled && isAppleSigninAvailable && mobiscroll.platform.name === 'ios' && isCordova() ?
                        <mobiscroll.FormGroup>
                            <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "auto"}}>
                                <mobiscroll.Button icon="empty icon fas fa-apple"
                                                   block={true}
                                                   onClick={this.cordovaAppleLogin}
                                                   className="md-social-btn apple-login-btn">
                                    Sign {this.state.isLogin ? "in" : "up"} with Apple
                                </mobiscroll.Button>
                            </div>
                        </mobiscroll.FormGroup>
                    : null}

                    {isAppleSigninEnabled && isAppleSigninAvailable && mobiscroll.platform.name !== 'android' && !isCordova() ?
                        <mobiscroll.FormGroup>
                            <div className="mbsc-align-center">
                                <AppleLogin clientId={appleSigninClientId}
                                            redirectURI={appleSigninRedirectUrl}
                                            designProp={{"width": 310, "height": 38, "type": (this.state.isLogin ? "sign-in" : "sign-up")}}
                                            scope={"name email"}
                                            responseType={"code id_token"}
                                            responseMode={"form_post"}
                                            state={encodeURIComponent(JSON.stringify(jwtState))}
                                />
                            </div>
                        </mobiscroll.FormGroup>
                    : null}

                    {isGoogleSigninEnabled && isGoogleSigninAvailable && isCordova() ?
                        <mobiscroll.FormGroup>
                            <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "auto"}}>
                                <mobiscroll.Button icon="empty icon fas fa-google"
                                                   block={true}
                                                   onClick={this.cordovaGoogleLogin}
                                                   className="md-social-btn google-login-btn"
                                                   style={{textTransform: "none"}}>
                                    Sign {this.state.isLogin ? "in" : "up"} with Google
                                </mobiscroll.Button>
                            </div>
                        </mobiscroll.FormGroup>
                        : null}

                    {isGoogleSigninEnabled && isGoogleSigninAvailable && !isCordova() ?
                        <mobiscroll.FormGroup>
                            <div className="mbsc-align-center" style={{maxWidth: "310px", margin: "auto"}}>
                                <GoogleLogin
                                    //useOneTap={true}
                                    //auto_select
                                    text={this.state.isLogin ? "signin_with" : "signup_with"}
                                    width={"310px"}
                                    logo_alignment={"center"}
                                    theme={"filled_black"}
                                    onSuccess={this.responseGoogleSuccess}
                                    onError={this.responseGoogleError}
                                />
                            </div>
                        </mobiscroll.FormGroup>
                        : null}
                </mobiscroll.Form>
                : null}

                <mobiscroll.Form
                    className="mpo-form-width-md"
                    labelStyle="stacked"
                    onSubmit={this.submit}
                    novalidate
                >
                    <mobiscroll.Card>
                        <mobiscroll.CardContent>
                            {isAppModePersonal() ?
                            <mobiscroll.FormGroup className="mbsc-padding mbsc-align-center">
                                <div className="mbsc-form-group-title" style={{borderBottom: "none !important"}}>{this.state.isLogin ? (this.state.isMobileLogin ? "Sign in with mobile" : "Sign in with email") : "Create a new account"}</div>
                            </mobiscroll.FormGroup>
                            : null}
                            <mobiscroll.FormGroup>
                                {this.state.isLogin ?
                                <React.Fragment>
                                {this.state.isMobileLogin ?
                                <React.Fragment>
                                    {/* mobile/pin login form */}
                                    <mobiscroll.Numpad
                                        fill="ltr"
                                        template="dddd ddd ddd"
                                        allowLeadingZero={true}
                                        placeholder=""
                                        showOnFocus={true}
                                        validate={validateMobile}
                                        onSet={this.onMobileChange}>
                                        <mobiscroll.Input
                                            labelStyle="inline"
                                            //errorMessage={this.state.mobileError}
                                            //valid={!this.state.mobileValid}
                                            value={this.state.mobile}>Mobile</mobiscroll.Input>
                                    </mobiscroll.Numpad>

                                    <mobiscroll.Numpad
                                        fill="ltr"
                                        template="dddddd"
                                        allowLeadingZero={true}
                                        placeholder=""
                                        showOnFocus={true}
                                        validate={validateAccountPin}
                                        onSet={this.onPinChange}>
                                        <mobiscroll.Input
                                            labelStyle="inline"
                                            type="password"
                                            passwordToggle={pinPasswordToggleEnabled}
                                            //valid={!this.state.pinValid}
                                            //errorMessage={this.state.pinError}
                                            value={this.state.pin}>PIN</mobiscroll.Input>
                                    </mobiscroll.Numpad>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {/* email/pass login form */}
                                    <mobiscroll.Input
                                        labelStyle="stacked"
                                        type="email"
                                        placeholder="my@email.com"
                                        value={this.state.email}
                                        onChange={this.onEmailChange}
                                        valid={this.state.emailValid}
                                        errorMessage={this.state.emailError}
                                        autoComplete="email"
                                    >{isAppModeKiosk() ? "Assigned Kiosk Email" : (isAppModePos() ? "Assigned POS Email" : "Email")}</mobiscroll.Input>

                                    <mobiscroll.Input
                                        labelStyle="stacked"
                                        type="password"
                                        passwordToggle={emailPasswordToggleEnabled}
                                        value={this.state.pass}
                                        onChange={this.onPasswordChange}
                                        valid={this.state.passValid}
                                        errorMessage={this.state.passError}
                                        autoComplete="current-password"
                                    >Password</mobiscroll.Input>
                                </React.Fragment>
                                }
                                <mobiscroll.Checkbox checked={this.state.rememberMe} onChange={this.onRememberMeChange}>
                                    Remember me
                                </mobiscroll.Checkbox>
                                </React.Fragment>
                                :
                                <React.Fragment>
                                    {/* signup form */}
                                    <mobiscroll.Input
                                        labelStyle="stacked"
                                        type="email"
                                        placeholder="my@email.com"
                                        value={this.state.email}
                                        onChange={this.onEmailChange}
                                        //valid={this.state.emailValid}
                                        //errorMessage={this.state.emailError}
                                        autoComplete="email"
                                        >Email</mobiscroll.Input>

                                    <mobiscroll.Input
                                        labelStyle="stacked"
                                        type="password"
                                        passwordToggle={true}
                                        value={this.state.pass}
                                        onChange={this.onPasswordChange}
                                        //valid={this.state.passValid}
                                        //errorMessage={this.state.passError}
                                        autoComplete="new-password"
                                        >Password (min. 6 characters)</mobiscroll.Input>

                                    <mobiscroll.Input
                                        labelStyle="stacked"
                                        placeholder="First Last"
                                        value={this.state.name}
                                        onChange={this.onNameChange}
                                        //valid={!this.state.nameValid}
                                        //errorMessage={this.state.nameError}
                                        autoComplete="name"
                                        >Name</mobiscroll.Input>

                                    <mobiscroll.Numpad
                                        fill="ltr"
                                        template="dddd ddd ddd"
                                        allowLeadingZero={true}
                                        placeholder=""
                                        showOnFocus={true}
                                        validate={validateMobile}
                                        onSet={this.onMobileChange}>
                                        <mobiscroll.Input
                                            labelStyle="stacked"
                                            //errorMessage={this.state.mobileError}
                                            //valid={!this.state.mobileValid}
                                            value={this.state.mobile}>Mobile</mobiscroll.Input>
                                    </mobiscroll.Numpad>

                                    <label>
                                        Country
                                        <mobiscroll.Select
                                            select="single"
                                            value={this.state.country}
                                            placeholder="Select..."
                                            onSet={this.setCountry}
                                            autoComplete="country"
                                        >
                                            <option key="0" value="">Select...</option>
                                            <option key="AU" value="AU">Australia</option>
                                            <option key="NZ" value="NZ">New Zealand</option>
                                        </mobiscroll.Select>
                                    </label>

                                    {this.state.country === 'AU' ?
                                    <label>
                                        State/Territory
                                        <mobiscroll.Select
                                            select="single"
                                            value={this.state.state}
                                            placeholder="Select..."
                                            onSet={this.setStateTerritory}
                                        >
                                            <option key="0" value="">Select...</option>
                                            <option key="ACT" value="ACT">ACT</option>
                                            <option key="NSW" value="NSW">NSW</option>
                                            <option key="NT" value="NT">NT</option>
                                            <option key="QLD" value="QLD">QLD</option>
                                            <option key="SA" value="SA">SA</option>
                                            <option key="TAS" value="TAS">TAS</option>
                                            <option key="VIC" value="VIC">VIC</option>
                                            <option key="WA" value="WA">WA</option>
                                        </mobiscroll.Select>
                                    </label>
                                    : null}
                                </React.Fragment>
                                }
                            </mobiscroll.FormGroup>
                            <mobiscroll.FormGroup className="mbsc-padding mbsc-align-center">
                                <mobiscroll.Button type="submit" color="primary" block={true}>{this.state.btnText}</mobiscroll.Button>
                            </mobiscroll.FormGroup>

                            <mobiscroll.FormGroup className="mbsc-padding mbsc-align-center">
                                {isAppModePersonal() ?
                                    <React.Fragment>
                                        {this.state.isLogin ?
                                        <div className="mbsc-align-center">
                                            <a href="#" onClick={this.mobileLogin}>{this.state.mobileBtnText}</a>
                                        </div>
                                        : null}
                                        <br/><br/>
                                        <div className="mbsc-align-center">
                                            <a href="#" onClick={this.signUp}>{this.state.signup}</a>
                                        </div>
                                        <br/><br/>
                                    </React.Fragment>
                                    : null }
                                <div className="mbsc-align-center"><a href="#" onClick={(e) => { e.preventDefault(); const location = { pathname: '/forgot',state: { fromForgotPin: this.state.isMobileLogin } }; this.props.history.push(location); }}>{this.state.forgotBtnText}</a></div>
                            </mobiscroll.FormGroup>

                        </mobiscroll.CardContent>
                    </mobiscroll.Card>

                    <React.Fragment>
                        {termsUrl === null || privacyUrl === null ?
                            <p className="mbsc-align-center mbsc-txt-muted mbsc-txt-s">
                                By using this {isCordova() ? "app" : "site"} you agree to our <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/terms');
                            }}>terms of use</a> and <a href="#" onClick={(e) => {
                                e.preventDefault();
                                this.props.history.push('/privacy');
                            }}>privacy policy</a>.</p>
                            :
                            (isCordova() ?
                                    <p className="mbsc-align-center mbsc-txt-muted mbsc-txt-s">
                                        By using this app you agree to our <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        openWindow(termsUrl, '_system', '');
                                    }}>terms of use</a> and <a href="#" onClick={(e) => {
                                        e.preventDefault();
                                        openWindow(privacyUrl, '_system', '');
                                    }}>privacy policy</a>.</p>
                                    :
                                    <p className="mbsc-align-center mbsc-txt-muted mbsc-txt-s">
                                        By using this site you agree to our <a href={termsUrl} target="_blank">terms of use</a> and <a href={privacyUrl} target="_blank">privacy policy</a>.
                                    </p>
                            )
                        }

                    </React.Fragment>
                </mobiscroll.Form>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        user: state.user,
        merchants: state.merchants
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateWithCustomer: (customer, ownProps, redirect) => {
            //console.log(ownProps);
            redirect = redirect || '/';
            if (redirect === '/' && ownProps !== undefined && ownProps !== null &&
                ownProps.hasOwnProperty('location') && ownProps.location !== undefined &&
                ownProps.location.hasOwnProperty('state') && ownProps.location.state !== undefined) {
                if (ownProps.location.state.hasOwnProperty('next')) {
                    redirect = ownProps.location.state.next;
                } else if (ownProps.location.state.hasOwnProperty('from') && ownProps.location.state.from !== '/login') {
                    redirect = ownProps.location.state.from;
                }
            }
            logger('updateStateWithCustomer redir3: '+redirect);
            dispatch(actions.setCustomerAction(customer, ownProps, redirect));
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AccountLogin);
